@mixin font-face($name, $file, $type) {
  @font-face {
    font-family: "#{$name}";
    @if ($type == ttf) {
      src: url("../assets/fonts/#{$file}.ttf") format('truetype') ;
    }
    @else {
      src: url("../assets/fonts/#{$file}.woff") format("woff") ;
    }
  }
}